<template>
  <div id="app">
 
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  created() {},
  mounted () {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
     
      this.$router.replace('/mb/index')
    } else {
    
      this.$router.replace('/pc/index')
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = window.navigator.userAgent.match(/(phone|iPhone|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }

};
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
</style>
