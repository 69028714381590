// 通过自定义指令 实现图片加载
export default {
    bind(el) {
        //1.将src保存后 清除el.src
        const src = el.src
        el.src = ''
        const observe = new IntersectionObserver((entries) => {
            //2.出现在可视区 赋予src 加载图片
            if (entries[0].isIntersecting) {
                el.src = src
                //关闭 observe观察 防止多次执行
                observe.unobserve(el)
            }
        })
        observe.observe(el)
    }
}
