import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/pc/index',
    name: 'home',
    component: ()=>import('@/views/pc.vue')
  },
  {
    path: '/mb/index',
    name: 'mb',
    component: ()=>import('@/views/mb.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  //vue页面跳转，不保留滚动位置，回到顶部
  scrollBehavior(to, from, saveTop) {
      if (saveTop) {
          return saveTop;
      } else {
          return {x: 0, y: 0}
      }
  }
});

export default router
